import React, {useEffect, useState} from "react";
import {useHistory, useLocation} from "react-router";
import { NavLink } from "react-router-dom";
import styled from "styled-components";

import { Layout, Menu, Popconfirm, Popover, Badge, Avatar } from "antd";
import {LoadingOutlined, MenuOutlined, UserOutlined} from "@ant-design/icons";

import { privateRoutes, privateRoutesLevel99 } from "../routes/routes";
import constant from "../data/constant";
import { useLogout } from "../recoil/auth";
import useSWR from "swr";
import NumberPoint from "../auth/NumberPoint";

const { Header, Content, Footer, Sider } = Layout;
const { SubMenu } = Menu;

const ServiceWrapper = ({ children }) => {
    const history = useHistory();

    const logout = useLogout();
    const location = useLocation();
    const [isCollapsed, setIsCollapsed] = useState(false);
    const [isVisibleProfilePopover, setIsVisibleProfilePopover] = useState(false);

    const pathName = location.pathname;
    const rootPath = pathName.substring(0, pathName.indexOf("/", 2));
    const pathArray = pathName.split("/")
    const { data: adminData, mutate: adminDataMutate} = useSWR(`/admin/user/info?access_token=${localStorage.getItem("token")}`);

    const [userMoneyNew, setUserMoneyNew] = useState(false);
    const [exchangeNew, setExchangeNew] = useState(false);
    const [qnaNew, setQnaNew] = useState(false);
    const [exchangeVisitNew, setExchangeVisitNew] = useState(false);

    const openKey = [];
    openKey[0] = pathArray.length > 2 ? rootPath : pathName;
    if (pathArray.length > 3) {
        openKey[1] = '/' + pathArray[1] + '/' + pathArray[2]
    }
    const { data, mutate } = useSWR(`/admin/main/dashboard`);

    useEffect(() => {
        if(data?.data?.user_money_last_id) {
            if (!localStorage.getItem('user_money_list_last_id') ||
                localStorage.getItem('user_money_list_last_id') != String(data?.data?.user_money_last_id)) {
                setUserMoneyNew(true);
            }
        }

        if(data?.data?.exchange_last_id) {
            if (!localStorage.getItem('exchange_list_last_id') ||
                localStorage.getItem('exchange_list_last_id') != String(data?.data?.exchange_last_id)) {
                setExchangeNew(true);
            }
        }

        if(data?.data?.qna_last_id) {
            if (!localStorage.getItem('qna_list_last_id') ||
                localStorage.getItem('qna_list_last_id') != String(data?.data?.qna_last_id)) {
                setQnaNew(true);
            }
        }

        if(data?.data?.exchange_visit_last_id) {
            if (!localStorage.getItem('exchange_visit_list_last_id') ||
                localStorage.getItem('exchange_visit_list_last_id') != String(data?.data?.exchange_visit_last_id)) {
                setExchangeVisitNew(true);
            }
        }
    }, [data]);

/*    if(isValidating){
        return <LoadingOutlined />
    }*/

    return (
        <Layout>
            <Sider
                collapsed={isCollapsed}
                onCollapse={(collapsedState) => setIsCollapsed(collapsedState)}
                width={260}
                style={{
                    overflow: "auto",
                    height: "100vh",
                    position: "fixed",
                    left: 0,
                    background: "white",
                    borderRight: "1px solid #f0f0f0",
                }}
            >
                {!isCollapsed && (
                    <LogoImage src={require("../assets/logo.png").default} />
                )}

                <Menu
                    mode="inline"
                    activeKey={location.pathname}
                    defaultOpenKeys={openKey}
                    selectedKeys={[location.pathname]}
                >
                    {adminData ?
                        (<>
                            {adminData?.data?.user_info?.admin_level == 99 ?
                                (
                                    <>
                                        {privateRoutesLevel99.map((parentRoute) =>
                                            (
                                                parentRoute.children ? (
                                                    <SubMenu
                                                        key={parentRoute.path}
                                                        icon={Object(parentRoute.sidebar).icon}
                                                        title={<span style={{ fontWeight : 'bold' }}>{Object(parentRoute.sidebar).label}</span>}
                                                        style={{ fontSize: 14 }}
                                                    >
                                                        {parentRoute.children.map(
                                                            ({ visible = true, ...childrenRoute }) =>
                                                                childrenRoute?.sub ? (<SubMenu
                                                                        key={`${parentRoute.path}${childrenRoute.path}`}
                                                                        title={Object(childrenRoute.sidebar).label}
                                                                        style={{ fontSize: 14 }}
                                                                    >
                                                                        {childrenRoute.sub.map(({ visible = true, ...sub }) =>
                                                                                visible && (
                                                                                    <Menu.Item
                                                                                        key={`${parentRoute.path}${childrenRoute.path}${sub.path}`}
                                                                                        icon={Object(sub.sidebar).icon}
                                                                                    >
                                                                                        <NavLink
                                                                                            to={`${parentRoute.path}${childrenRoute.path}${sub.path}`}
                                                                                            className="nav-text"
                                                                                            style={{ fontSize: 14 }}
                                                                                        >
                                                                                            {Object(sub.sidebar).label}
                                                                                        </NavLink>
                                                                                    </Menu.Item>
                                                                                )
                                                                        )}
                                                                    </SubMenu>)
                                                                    : (
                                                                        visible && (
                                                                            <Menu.Item
                                                                                key={`${parentRoute.path}${childrenRoute.path}`}
                                                                                icon={Object(childrenRoute.sidebar).icon}
                                                                            >
                                                                                <NavLink
                                                                                    to={`${parentRoute.path}${childrenRoute.path}`}
                                                                                    className="nav-text"
                                                                                    style={{ fontSize: 14 }}
                                                                                >
                                                                                    {Object(childrenRoute.sidebar).label}
                                                                                </NavLink>
                                                                            </Menu.Item>
                                                                        )
                                                                    )
                                                        )}
                                                    </SubMenu>
                                                )  : (
                                                    <Menu.Item
                                                        key={parentRoute.path}
                                                        icon={Object(parentRoute.sidebar).icon}
                                                    >
                                                        <NavLink
                                                            to={parentRoute.path}
                                                            className="nav-text"
                                                            style={{ fontSize: 14 }}
                                                        >
                                                            {Object(parentRoute.sidebar).label}
                                                        </NavLink>
                                                    </Menu.Item>
                                                )
                                            )
                                        )}
                                    </>
                                )
                                :
                                (
                                    <>
                                        {privateRoutes.map((parentRoute) =>
                                            (
                                                parentRoute.children ? (
                                                    <SubMenu
                                                        key={parentRoute.path}
                                                        icon={Object(parentRoute.sidebar).icon}
                                                        title={<span style={{ fontWeight : 'bold' }}>{Object(parentRoute.sidebar).label}</span>}
                                                        style={{ fontSize: 14 }}
                                                    >
                                                        {parentRoute.children.map(
                                                            ({ visible = true, ...childrenRoute }) =>
                                                                childrenRoute?.sub ? (<SubMenu
                                                                        key={`${parentRoute.path}${childrenRoute.path}`}
                                                                        title={Object(childrenRoute.sidebar).label}
                                                                        style={{ fontSize: 14 }}
                                                                    >
                                                                        {childrenRoute.sub.map(({ visible = true, ...sub }) =>
                                                                                visible && (
                                                                                    <Menu.Item
                                                                                        key={`${parentRoute.path}${childrenRoute.path}${sub.path}`}
                                                                                        icon={Object(sub.sidebar).icon}
                                                                                    >
                                                                                        <NavLink
                                                                                            to={`${parentRoute.path}${childrenRoute.path}${sub.path}`}
                                                                                            className="nav-text"
                                                                                            style={{ fontSize: 14 }}
                                                                                        >
                                                                                            {Object(sub.sidebar).label}
                                                                                        </NavLink>
                                                                                    </Menu.Item>
                                                                                )
                                                                        )}
                                                                    </SubMenu>)
                                                                    : (
                                                                        visible && (
                                                                            <Menu.Item
                                                                                key={`${parentRoute.path}${childrenRoute.path}`}
                                                                                icon={Object(childrenRoute.sidebar).icon}
                                                                            >
                                                                                <NavLink
                                                                                    to={`${parentRoute.path}${childrenRoute.path}`}
                                                                                    className="nav-text"
                                                                                    style={{ fontSize: 14 }}
                                                                                >
                                                                                    {Object(childrenRoute.sidebar).label}
                                                                                </NavLink>
                                                                            </Menu.Item>
                                                                        )
                                                                    )
                                                        )}
                                                    </SubMenu>
                                                )  : (
                                                    <Menu.Item
                                                        key={parentRoute.path}
                                                        icon={Object(parentRoute.sidebar).icon}
                                                    >
                                                        <NavLink
                                                            to={parentRoute.path}
                                                            className="nav-text"
                                                            style={{ fontSize: 14 }}
                                                        >
                                                            {Object(parentRoute.sidebar).label}
                                                        </NavLink>
                                                    </Menu.Item>
                                                )
                                            )
                                        )}
                                    </>
                                )
                            }
                        </>)

                        :

                        (<LoadingOutlined />)
                    }
                </Menu>
            </Sider>
            <Layout
                style={{
                    marginLeft: isCollapsed ? 80 : 260,
                    transition: "all 0.2s",
                    minHeight: "100vh",
                    backgroundColor: "white"
                }}
            >
                <Header
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        padding: "0px 15px",
                        backgroundColor: "white",
                        boxShadow: "0 2px 8px #f0f1f2"
                    }}
                >
                    <MenuOutlined
                        style={{fontSize: 20}}
                        onClick={() => setIsCollapsed((prevState) => !prevState)}
                    />
                    {adminData ?
                        (<>
                            {adminData?.data?.user_info?.admin_level != 99 ?
                                <MainDashBoard>
                                    <div className={'money-board'} onClick={() => {
                                        setUserMoneyNew(false);
                                        history.push(`/pay/money`);
                                        window.location.reload();
                                    }
                                    }>
                                        <span>티프 머니 출금 목록 : {data?.data?.user_money_count}개</span>
                                        {userMoneyNew &&
                                            <span className='new'>&nbsp;N</span>
                                        }
                                    </div>
                                    <div className={'exchange-board'} onClick={() => {
                                        setExchangeNew(false);
                                        history.push(`/request/exchange/card`);
                                        window.location.reload();
                                    }
                                    }>
                                        <span>디지털 카드 교환 목록 : {data?.data?.exchange_count}개</span>
                                        {exchangeNew &&
                                            <span className='new'>&nbsp;N</span>
                                        }
                                    </div>
                                    <div className={'qna-board'} onClick={() => {
                                        setQnaNew(false);
                                        history.push(`/cs/qna`);
                                        window.location.reload();
                                    }
                                    }>
                                        <span>1:1 문의 목록 : {data?.data?.qna_count}개</span>
                                        {qnaNew &&
                                            <span className='new'>&nbsp;N</span>
                                        }
                                    </div>
                                </MainDashBoard>
                                : (<>
                                <MainDashBoard>
                                    <div className={'exchange-board'} onClick={() => {
                                        setExchangeVisitNew(false);
                                        history.push(`/request/exchange/card/visit`);
                                        window.location.reload();
                                    }
                                    }>
                                        <span>카드 현장 수령 목록 : {data?.data?.exchange_visit_count}개</span>
                                        {exchangeVisitNew &&
                                            <span className='new'>&nbsp;N</span>
                                        }
                                    </div>
                                    <div className={'qna-board'} onClick={() => {
                                        setQnaNew(false);
                                        history.push(`/cs/qna`);
                                        window.location.reload();
                                    }
                                    }>
                                        <span>1:1 문의 목록 : {data?.data?.qna_count}개</span>
                                        {qnaNew &&
                                            <span className='new'>&nbsp;N</span>
                                        }
                                    </div>
                                </MainDashBoard>
                                <MoneyInfo>
                                    <div>
                                        <span>MONEY : {NumberPoint(data?.data?.user_money ?? 0)}원</span>
                                    </div>
                                    <div>
                                        <span>COIN : {NumberPoint(data?.data?.user_point ?? 0)}코인</span>
                                    </div>
                                </MoneyInfo>
                                </>)
                            }
                        </>)
                        :
                        (<LoadingOutlined/>)
                    }
                    <Popover
                        trigger="click"
                        placement="bottomRight"
                        content={
                            <>
                                <PopoverContents>
                                    <Popconfirm
                                        title="로그아웃 하시겠습니까?"
                                        onConfirm={logout}
                                        okText="확인"
                                        cancelText="취소"
                                    >
                  <span style={{color: "black", cursor: "pointer"}}>
                    로그아웃
                  </span>
                                    </Popconfirm>
                                </PopoverContents>
                            </>
                        }
                        visible={isVisibleProfilePopover}
                        onVisibleChange={(visibleState) =>
                            setIsVisibleProfilePopover(visibleState)
                        }
                    >
                        < UserOutlined/>
                    </Popover>
                </Header>
                <Content style={{margin: "24px 16px 0"}}>
                    {children}
                </Content>
                <Footer style={{textAlign: "center"}}>{constant.footerText}</Footer>
            </Layout>
        </Layout>
    );
};

const ProfileImage = styled.img`
    width: 25px;
    height: 25px;
    border-radius: 12.5px;
    cursor: pointer;
`;

const PopoverContents = styled.div`
    width: 150px;
`;

const MainDashBoard = styled.div`
    width: 50%;
    display: flex;
    margin: 2px 150px 0 150px;
    height: 70%;

    & .money-board {
        background-color: rgb(242, 218, 0);
    }

    & .exchange-board {
        background-color: rgb(212, 246, 72);  
    }
    
    & .qna-board{
        background-color: #F3F3F3;
    }
    
    & > div {
        cursor: pointer;
        margin-right: 20px;
        text-align: center;
        font-weight: bold;
        width: 100%;
        font-family: 'Noto Sans KR' !important;
        border-radius: 5px;
        justify-content: center;
        align-items: center;
        display: flex;
        white-space: nowrap;
        color: #303030c2;
        & .new{
            color: orangered;
        }
    }
`;

const LogoImage = styled.img`
  width: 100%;
  font-family: "Nanum Gothic", sans-serif;
  font-weight: 700;
  font-size: 24px;
  text-align: center;
  padding: 40px;
`;

const MoneyInfo = styled.div`
    border: 1px solid white;
    background: #1E1E1E;
    height: 70%;
    justify-content: space-between;
    display: flex;
    padding: 0 20px;
    width: 25%;
    border-radius: 5px;
    
    & > div {
        margin: 0 auto;
        display: flex;
        cursor: pointer;
        text-align: center;
        justify-content: center;
        align-items: center;
        font-weight: bold;
        width: 100%;
        color: white;
        height: 100%;
        white-space: nowrap;
    }
`;
export default ServiceWrapper;
